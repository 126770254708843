<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-04 11:01:22
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-26 15:25:44
-->
<template>
  <div class="container ">
    <div class="d-flex pt-2 pb-2 pl-2">
      <div class="header-optimizedDecision">
        年龄占比分析
      </div>
    </div>
    <div class="content">
      <p class="d-flex jc-between ai-center">
        <span>学习中人数</span>

        <span class="text-warning">{{originData.studyCount}}人</span>
      </p>
      <p class="d-flex jc-between ai-center">
        <span>已报名人数</span>

        <span class="text-warning">{{originData.trainCount}}人</span>
      </p>
      <p class="d-flex jc-between ai-center">
        <span>已毕业人数</span>

        <span class="text-warning">{{originData.examCount}}人</span>
      </p>
    </div>
    <div class="d-flex jc-center">
      <Chart :originData="originData.pieDate" />
    </div>
  </div>
</template>

<script>
  import Chart from "./chart.vue";

  export default {
    components: {
      Chart,
    },
    props: {
      originData: {
        type: [Object, Array],
        default: () => ({}),
      },
    },
  };
</script>

<style lang="scss" scoped>
  $box-height: 360px;

  .container {
    height: $box-height;

    .content {
      padding: 0 30px;

      p {
        color: white;
        font-size: 20px;
        font-weight: 900;
        text-align: left;
        padding: 5px;
      }
    }
  }
</style>