<!--
 * @Description: 
 * @Version: 2.0
 * @Autor: guozong
 * @Date: 2022-07-03 18:46:35
 * @LastEditors: guozong
 * @LastEditTime: 2022-08-26 15:26:38
-->
<template>
  <div>
    <Echart id="centreLeft2Chart" ref="centreLeft2ChartRef" :options="options" height="200px" width="420px"></Echart>
  </div>
</template>

<script>
  import Echart from "@/common/echart";
  import {
    getRoudom
  } from "@/utils";
  export default {
    data() {
      return {
        options: {},
      };
    },
    components: {
      Echart,
    },
    props: {
      originData: {
        type: [Array, Object],
        default: () => [],
      },
    },
    watch: {
      originData: {
        handler(originData) {

          this.options = {
            // title: {
            //   text: 'World Population'
            // },
            tooltip: {
              trigger: 'axis',
              axisPointer: {
                type: 'shadow'
              }
            },
            // legend: {},
            grid: {
              top: '10%',
              left: '3%',
              right: '4%',
              bottom: '3%',
              containLabel: true
            },
            series: [{
              name: 'Nightingale Chart',
              type: 'pie',
              radius: [0, 70],
              center: ['50%', '60%'],
              label: {
                fontSize: 20
              },
              itemStyle: {
                borderRadius: 8
              },
              data: originData.map(i => {
                return {
                  value: i.userCount,
                  name: i.ageRange,
                }
              })
            }]
          }
        },
        immediate: true,
        deep: true,
      },
    },
    methods: {
      getRoudom
    },
  };
</script>